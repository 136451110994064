import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function Rillusion() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c8_ee9312f087.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Rillusion
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                Rillusion is currently the best UI UX design company in Chennai.
                The design company was founded in 2010 with a goal to design
                digital products
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $25-$49 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 10-49
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.rillusion.com/" target="_blank">
                  https://www.rillusion.com/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | 5th floor, Gokul Arcade, 2, Sardar Patel Rd,
                  Baktavatsalm Nagar, Adyar, Chennai, Tamil Nadu 600020
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                Rillusion is currently{" "}
                <b>the best UI UX design company in Chennai</b>. The design
                company was founded in 2010 with a goal to design digital
                products that will help put a client's business on the map. The
                company currently not only designs apps for mobiles and web, but
                it also offers website design and development services. The team
                at Rillusion is also knowledgeable in advanced technologies like
                the Internet of Things and can create IoT apps as well as
                develop apps that are compatible with wearable tech. Ever since
                the inception of the company, the team has managed to complete
                500 projects and have worked with several top clients, namely,
                Sodexo, Armour, Ovenfresh, HDFC Bank, Axis Bank, etc.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
